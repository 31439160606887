/* You can add global styles to this file, and also import other style files */
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import 'primeicons/primeicons.css';
@import 'quill/dist/quill.snow.css';
@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";
@import '../node_modules/swiper/swiper-bundle.css';
@import "~@ng-select/ng-select/themes/default.theme.css";

.btn-view button {
    background-color: rgb(25 135 84 / 90%);
    border-color: rgb(25 135 84 / 90%);
}

.btn-view button:hover {
    background-color: rgb(25 135 84 / 100%) !important;
    border-color: rgb(25 135 84 / 100%) !important;
}

.btn-remove button {
    background-color: rgb(255 0 0 / 80%);
    border-color: rgb(255 0 0 / 80%);
}

.btn-remove button:hover {
    background-color: rgb(255 0 0 / 100%) !important;
    border-color: rgb(255 0 0 / 100%) !important;
}

.p-fileupload .p-fileupload-content {
    padding: 0;
}

.custom-file .p-element:not(span, button) {
    display: none !important;
}

/*.p-editor-container .p-editor-toolbar.ql-snow {*/
/*    height: 0;*/
/*    padding: 0;*/
/*}*/

.box-table tbody td {
    vertical-align: middle;
}

.box-table td {
    text-align: center;
    padding: 0.75rem 1rem !important;
}

.p-dialog-mask.p-component-overlay {
    z-index: 1000 !important;
}

.content-header #dropdownBasic1 {
    display: none;
}

.table-img {
    max-width: 300px;
}


.table-img img {
    width: 100%;
    object-fit: contain;
}

.txt-line {
    max-width: 250px;
}

.txt-line p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    display: block;
    margin-bottom: 5px;
}

.txt-line p:not(:first-child) {
    display: none;
}

.view-edit-img img{
    width: 100%;
    max-width: 500px;
}

.radio-input input {
    display: none;
}

.radio-input {
    --container_width: 370px;
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 9999px;
    background-color: #fff;
    color: #000000;
    width: var(--container_width);
    overflow: hidden;
    border: 1px solid rgba(53, 52, 52, 0.226);
}

.radio-input label {
    width: 100%;
    padding: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    font-weight: 600;
    letter-spacing: -1px;
    font-size: 14px;
    margin-bottom: 0;
}

.selection {
    display: none;
    position: absolute;
    height: 100%;
    width: calc(var(--container_width) / 3);
    z-index: 0;
    left: 0;
    top: 0;
    transition: .15s ease;
}

.radio-input label:has(input:checked) {
    color: #fff;
}

.radio-input label:has(input:checked) ~ .selection {
    background-color: rgb(11 117 223);
    display: inline-block;
}

.radio-input label:nth-child(1):has(input:checked) ~ .selection {
    transform: translateX(calc(var(--container_width) * 0/3));
}

.radio-input label:nth-child(2):has(input:checked) ~ .selection {
    transform: translateX(calc(var(--container_width) * 1/3));
}

.radio-input label:nth-child(3):has(input:checked) ~ .selection {
    transform: translateX(calc(var(--container_width) * 2/3));
}

.input-pw, .input-pw .p-inputwrapper, .input-pw .p-component {
    display: block;
    width: 100%;
}


.form-steps {
    width: 100%;
    clear: both;
    display: flex;
    justify-content: center;
}
.form-steps:before, .form-steps:after {
    display: table;
    content: "";
}
.form-steps:after {
    clear: both;
}

.form-steps__step {
    width: 50%;
    float: left;
    padding: 1rem;
    text-align: center;
    position: relative;
}
.form-steps__step:after {
    content: "";
    background: #eee;
    height: 0.2rem;
    top: 2.29rem;
    left: 50%;
    width: 100%;
    display: block;
    position: absolute;
    z-index: 1;
}
.form-steps__step--last:after {
    display: none;
}
.form-steps__step--active .form-steps__step-circle {
    background: #2260ff;
    border: 1px solid #111;
    color: #fff;
}
.form-steps__step--completed:after {
    background: #2260ff;
}
.form-steps__step--completed .form-steps__step-circle {
    background: #2260ff;
    border: 1px solid #111;
    color: #fff;
}
.form-steps__step--completed .form-steps__step-circle .form-steps__step-number {
    display: none;
}
.form-steps__step--completed .form-steps__step-circle .form-steps__step-check {
    display: inline-block;
}

.form-steps__step-circle {
    background: #eee;
    border: 1px solid #bdbdbd;
    line-height: 2.4rem;
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem;
    text-align: center;
    color: #bdbdbd;
    margin: 0 auto;
    position: relative;
    z-index: 3;
}

.form-steps__step-check {
    display: none;
}

.form-step__step-name {
    margin-top: 0.8rem;
    display: inline-block;
    font-size: 1rem;
    line-height: 1.4rem;
}


.view-edit-content *{
    text-align: left;
}

p-inputnumber, .p-inputnumber{
    width: 100%;
}


@media screen and (max-width: 992px) {
    .position {
        margin-bottom: 1.5rem;
    }
}

@media screen and (max-width: 750px) {
    .box-head, .box-filter{
        flex-direction: column;
        gap: 10px;
    }

    .box-filter span.p-input-icon-right{
        width: fit-content;
    }
}
